import React, { useState } from "react";

import s from "./button-select.module.scss";
import classNames from "classnames";

type ButtonSelectStates = "default" | "selected" | "disabled";

interface ButtonSelectProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
	state?: ButtonSelectStates;
}

export const ButtonSelect = ({
	state = "default",
	className,
	onClick,
	children,
	...props
}: ButtonSelectProps) => {
	const [buttonState, setButtonState] = useState<ButtonSelectStates>(state);

	return (
		<button
			{...props}
			disabled={buttonState === "disabled"}
			aria-checked={(buttonState === "selected").valueOf()}
			className={classNames(
				s.container,
				{
					[s.selected]: buttonState === "selected",
				},
				className,
			)}
			onClick={e => {
				if (buttonState === "disabled") return;

				onClick && onClick(e);
				setButtonState(currState => (currState === "selected" ? "default" : "selected"));
			}}
		>
			{children}
		</button>
	);
};
