import { SimplifiedPlace } from "#resources/api/enterprise-generated";
import { EnterpriseApiSignature } from "#resources/helpers/api";
import { SdkgenError } from "@sdkgen/browser-runtime";
import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { PLACE_KEYS } from "./keys";
import { showErrorNotification } from "#resources/helpers/notifications";
import enterprise from "#resources/api/enterprise-client";
import { createQueryFactory } from "../query-factory";

type SimplifiedPlacesSignature = EnterpriseApiSignature<"getSimplifiedPlaces">;
type UseSimplifiedPlacesOptions = Omit<
	UseQueryOptions<SimplifiedPlacesSignature["result"], SdkgenError>,
	"queryKey"
>;

export const useSimplifiedPlaces = <TData = SimplifiedPlace[]>(
	options?: UseSimplifiedPlacesOptions,
	select?: (data: SimplifiedPlace[]) => TData,
) => {
	return useQuery({
		...options,
		queryKey: PLACE_KEYS.simplifiedPlaces,
		queryFn: () => enterprise.getSimplifiedPlaces(),
		throwOnError(error) {
			showErrorNotification(error.message);

			return false;
		},
		select,
	});
};

export const { useApiHook: useGetPlaceProducts } = createQueryFactory("getPlaceProducts");
export const { useApiHook: useGetAddressFromCep } = createQueryFactory(
	"getAddressFromCep",
);
export const { useApiHook: useGetPlaceSettingsGeneralSection } = createQueryFactory(
	"getPlaceSettingsGeneralSection",
);
