import { vendor } from "./vendor";

export const settings = {
	title: "Edit settings",
	error: "No permission to edit place",
	attention: "Attention!",
	url: "productname",
	daysAndWorkingPeriod: "Days and working hours",
	address: {
		postalCode: "Postal Code",
		state: "State",
		city: "City",
		neighborhood: "Neighborhood",
		address: "Address",
		number: "Number",
		complement: "Complement",
	},
	workingPeriod: {
		deletedPeriod: "Period deleted.",
		addDaysAndTime: "Add days and times",
		addOpeningHours: "Add opening hours",
		daysOfTheWeek: "Days of the week",
		start: "Start",
		end: "End",
	},
	menu: {
		title: "Other configuration options",
		general: {
			title: "General",
			subtitle: "Inform limits, e-mails and others",
		},
		record: {
			title: "Record",
			subtitle: "Configure token mode",
		},
		couvert: {
			title: "Couvert",
			subtitle: "Set the cover charge",
		},
		features: {
			title: "Features",
			subtitle: "Enable desired features",
		},
		pdv: {
			title: "PDV",
			subtitle: "Configure the point of sale",
		},
		totem: {
			title: "Totem",
			subtitle: "Customize a totem",
		},
		refundReason: {
			title: "Refund/Cancel Reasons",
			subtitle: "Configure reasons for the point of sale",
		},
		vendor: {
			title: "Vendor",
			subtitle: "Configure general information",
		},
	},
	warningDialog:
		"You are enabling postpaid payments on '{{name}}'. Are you sure about this? To confirm the modification, please type '{{text}}'",
	data: {
		label: "Data",
		name: "Name",
		selectProduct: "Select a product to enable automatic security deposit",
		noneProduct: "No products",
		peopleCapacity: "Capacity",
		slug: "Slug",
		peopleCapacityTooltip: "Number of people the place holds",
	},
	passTemplate: "ID do modelo do passe",
	limits: {
		label: "Limits and services",
		postPaidLimit: "Postpaid limit",
		serviceTax: "Service charge",
		rechargeNotIntegratedLimit: "Non-integrated recharge limit",
		nonIntegratedPaymentLimit: "Non-integrated payment limit per device and event",
		enableBuiltInLimit: "Enable non-integrated recharge limit",
		enableNonIntegratedPaymentLimit:
			"Enable non-integrated payment limit per device and event",
		nonIntegratedPaymentLimitTooltip:
			"This limit refers to the maximum amount of non-integrated payments that a device can receive during the event. This parameter is the sum of total Recharges and Sales without a Card made with non-integrated payments",
		nonIntegratedRechargeLimitTooltip:
			"This limit refers to the maximum amount that an employee can make in a non-integrated recharge",
	},
	settings: {
		label: "Settings",
		myActionsPassword: {
			label: "¨My Actions¨ password",
			placeholder: "Enter a numeric password or the default password will be 9090",
		},
		sellVisualizationFormat: {
			label: "Display format of products on sale",
			grid: "Grid",
			list: "List",
		},
	},
	everest: {
		label: "Everest",
		user: "User",
		password: "Password",
		cnpj: "CNPJ",
		host: "Host",
	},
	placeFeatures: "Functionalities",
	email: "Emails to receive closing events",
	emailError: "Invalid email",
	consumption: {
		label: "Minimum consumption tax setup",
		fiscalProfile: "Fiscal profile",
		fiscalGroup: "Fiscal group",
		ncm: "NCM",
	},
	alert: "Modifications have been made, save to keep them",
	save: "Save",
	image: "Image",
	ficha: {
		label: "Ficha",
		text: "Title of the Form",
		placeholderText: "Insert the form title",
		logo: "Image in the form",
		upload: "Attach image",
		formatError: "Unsupported format",
		imageFormatMessage: "The image must be in PNG or SVG format.",
		recommendationMessage:
			"We recommend that the assigned image be the <strong>company logo</strong> in <strong>black color</strong>, as it is not possible to print in another color.",
		dragOrSelectMessage:
			"Drag the image from your computer or select it from 'Attach image'.",
		logoFormatMessage:
			"The <strong>background of the image/logo must be white</strong> to appear transparent in printing.",
		dimensionsMessage:
			"The suggested dimensions for good resolution are <strong>472x472</strong> px.",
	},
	couvertEdit: {
		label: "Edit couverts",
		editCouvert: "Edit couvert",
		male: "male",
		female: "female",
		men: "Men",
		women: "Women",
		edit: "Edit",
		add: "Add",
		cancel: "Cancel",
		columns: {
			public: "Public",
			name: "Name",
			category: "Category",
			price: "Price",
		},
		error: {
			required: "This field is required",
			value: "The cover charge must be less than or equal to the postpaid limit",
		},
	},
	devices: {
		label: "Place PDVs ({{counter}})",
		placeholder: "Add a PDV to this location",
	},
	errors: {
		invalidName: "Please enter a valid name",
		invalidTip: "Enter a value of up to 25%",
		postPaidLimit: "Please enter a valid value",
		rechargeNotIntegratedLimit: "Minimum value of",
		hasConsumptionObligation: "Mandatory field for consumption obligation configuration",
		minNonIntegratedPaymentLimit: "Minimum value of {{value}}",
		tipOptionsValuesRequired: "Tip values are required",
		tipOptionLowerValue:
			'The "Lower Value" must be less than the Medium and Higher values',
		tipOptionHigherValue: 'The "Higher Value" must be greater than the Medium value',
	},
	totem: {
		logoSelectDescription:
			"<strong>Select the logo that best fits your image</strong> (disregard the background color)",
		addLogo: "Add",
		important: "Important",
		instructions: "Recommendations",
		image:
			"The image should have dimensions of <strong>1080 x 1920</strong> px in height",
		logo:
			"The <strong>Zig</strong> logo will appear over the assigned image, centered at the top.",
		recommendations:
			"It is recommended to insert the message <strong>'tap to start'</strong> to guide the user",
		defaultMessage:
			"If you don't add a cover image, " +
			"Zig's default screen will be your home screen " +
			"(see the first image under <strong>'Colors'</strong>.)",
		totemImgLabel: "Home screen image (optional)",
		totenCustomizationLabel: "Totem Cover Customization",
		removeTotemImage: {
			title: "Do you want to delete the home screen?",
			content: "By deleting it, the home screen will revert to Zig's default screen.",
			confirmBtn: "Yes, I want to delete",
			cancelBtn: "No",
		},
		colors: "Colors",
		saveMessage: "Save customization",
	},
	tip: {
		taxType: "Tax Type",
		tipSuggestions: "Tip Suggestions",
		tip: "Tip",
		serviceTax: "Service Charge",
		none: "None",
		lowerValue: "Lower Value",
		medValue: "Medium Value",
		higherValue: "Higher Value",
		tooltip:
			"Tip recommendations make paying the bill easier by offering three percentage options (Smaller, Medium, and Larger) based on the total amount of the bill, for example: 5%, 10%, and 15%, capped at 25%.",
	},
	vendor,
	invoiceMoment: {
		fieldLabel: "Select the moment for cashless invoice issuance",
		tip:
			"Select the timing of the invoice issuance for Cashless payments. For all other payment methods that are not Cashless, the invoice will always be issued upon consumption",
		recharge: "Recharge",
		rechargeAndActivation: "Recharge and activation",
		consumption: "Consumption",
		consumptionAndActivation: "Consumption and activation",
	},
	refundReason: {
		addReason: "Create new reason",
		cancel: "Cancellation",
		modalDelete: {
			confirmation:
				"Do you want to remove the reason from the list of refund and cancellation reasons?",
			description:
				"By removing the reason, it will no longer appear, but it will remain in old reports.",
			title: "Remove reason",
		},
		modalForm: {
			addReason: "Create Reason",
			allowOpenText: "Enable justification",
			creatingReason: "Creating a reason",
			editReason: "Save changes",
			editingReason: "Editing a reason",
			reason: "Reason name",
			reasonPlaceholder: "Enter the name for the refund and/or cancellation reason",
		},
		reason: "Reason name",
		removeReason: "Edit reason",
		refund: "Refund",
		table: {
			editReason: "Edit reason",
			observation: "Observation",
			reason: "Reason name",
			removeReason: "Remove reason",
			status: "Status",
			type: "Type",
		},
		title: "Refund and Cancellation Reasons",
	},
	defaultDateFilter: "Default date filter",
};
