export const precharge = {
	title: "Operações de pré-carga",
	searchPlaceholder: "Pesquise por nome da operação de pré-carga",
	createNewPrecharge: "Criar operação de pré-carga",
	noSearchResults: "A busca não encontrou resultados",
	empty: "Não existem operações de pré-carga cadastradas",
	card: {
		noTermsOfService: "Sem termos de serviço",
		noEnd: "Sem término",
	},
	addNewPrecharge: {
		prechargeOperation: "operação de pré-carga",
		siteConfig: "Configurações do site",
		eventName: "Nome do evento",
		termsOfService: "Termos de serviços",
		ticketUrl: "Ticket url",
		slug: "Slug",
		aliases: "Alias",
		siteAppearance: "Aparência do site",
		cover: "Cover",
		logo: "Logo",
		mainImage: "Imagem principal",
		primaryColor: "Cor primária",
		darkMode: "Dark Mode",
		operationConfig: "Configurações da operação",
		beginAt: "Começa em",
		endsAt: "Termina em",
		childFriendly: "Adequado para crianças",
		preSetedValues: "Valores pré-selecionáveis",
		bonus: "Bonus",
		minimumValue: "Valor mínimo",
		rechargeConfig: "Configurações da recarga",
		expirationDate: "Data de expiração das recargas",
		validRechargDate: "Regargas são válidas apartir de",
		rechargeDuration: "Duração de recarga",
	},
	sideTab: {
		edit: "Editar operação de pré-carga",
		withoutTerms: "Sem termos de serviço",
		preChargeOperation: "Operação de pré-carga",
		slug: "SLUG",
		beginAt: "Começa em",
		endsAt: "Termina em",
		expiresAt: "Data de expiração das recargas",
		undefined: "Não definido",
		validRecharges: "Regargas são válidas apartir de",
		darkMode: "Dark Mode",
		childFriendly: "Adequado para crianças",
		primaryColor: "Cor primária",
		durability: "Duração de recarga",
		remove: {
			title: "Tem certeza que quer apagar essa operação de pré-carga?",
			button: "Apagar Operação de pré-carga",
		},
	},
	prechargeStore: {
		createPrechargeOperationSuccess: "Operação de pré-carga criada com sucesso",
		updatePrechargeOperationSuccess: "Operação de pré-carga atualizada com sucesso",
		deletePrechargeOperationSuccess: "Operação de pré-carga apagada com sucesso",
	},
	homePage: {
		deleteConfirmation: "Clique aqui para confirmar a exclusão da operação",
		title: "Sites de Pré-Carga",
		description:
			"Crie um site para que os clientes realizem recarga antes do evento começar, evitando filas e consequentemente diminuindo o volume de operação no evento.",
		createButton: "Criar site de recarga",
		searchInputPlaceholder: "Busque um site",
		willExpire: "Irá expirar em",
		hasExpired: "Expirou em",
		copyLink: "Copiar link para o site",
		active: "Ativo",
		inactive: "Inativo",
		copied: "Link copiado.",
		report: "Relatório",
	},
	formPage: {
		title: "Criar site de recarga",
		description:
			"Preencha os dados e divulgue o site para seus clientes. Permita que os usuários realizem a recarga antes do evento começar, evitando filas e consequentemente diminuindo o volume de operação no evento.",
		nextStep: "Próxima etapa",
		discard: "Descartar criação",
		logoDescription: "A imagem deve ser em .jpeg ou .png, com 880px por 880px",
		eventData: {
			logo: "Logo do evento",
			eventName: "Nome do evento",
			eventNamePlaceholder: "Digite o nome do evento",
			email: "E-mail para contato",
		},
		customization: {
			slug: "URL do site",
			slugPlaceholder: "Digite o nome do evento",
			color: "Cor dos detalhes",
			rechargesPeriod: "Permitir recargas no período de",
			endsAt: "Tirar o site do ar em",
			buffer: "Foto de destaque da página",
			mainPageInfoTitle: "Título da página inicial",
			mainPageInfoTitleDescription:
				'Sugestão: Recarga antecipada? O(A) "{{name}}" tem! Se programe e evite filas',
			mainPageInfoTitlePlaceholder: "Digite aqui o título que deseja colocar na página",
			mainPageInfoSubtitle: "Texto complementar ao título",
			colorPlaceholder: "Selecionar cor",
		},
		rechargeSettings: {
			rechargesLimit: "Limites de recarga",
			rechargesLimitDescription:
				"Estabeleça o valor mínimo por recarga e o valor máximo por usuário (soma de todas as recargas antecipadas no período da operação).",
			minimumValue: "Valor mín. de recarga",
			maximumValuePerUser: "Valor máx. de recarga por usuário (período da operação)",
			suggestion: "Sugestão de valor de recarga",
			suggestionDescription:
				"No site de recarga, o usuário poderá selecionar um dos valores já sugeridos ou digitar o valor que desejar, respeitando os limites mínimo e máximo estabelecidos acima. Sugira 4 valores para serem exibidos no site.",
			preSetedValue: "Valores selecionáveis",
			bonus: "Atribuição de bônus",
			bonusDescription:
				"Sabia que você pode presentear seus clientes com bônus? A cada valor de recarga sugerido acima você poderá atrelar um bônus, caso deseje.",
			paymentMethods: "Formas de pagamento",
			paymentMethodsDescription:
				"Estabeleça se o site de recarga aceitará como forma de pagamento apenas Pix, apenas cartão de crédito ou os dois.",
			bonusTable: {
				rechargeValue: "Valor da Recarga",
				bonus: "Bônus",
			},
			addValue: "Adicionar valor sugerido ao site",
			suggestedValue: "Valores sugeridos adicionados",
			removeValue: "clique no X para remover",
			noBonus: "Sem bonus",
		},
		tips: {
			yesOpt: "Sim. Adicionar seção de dicas no site.",
			noOpt: "Não. Não adicionar seção de dicas no site.",
			mainQuestion:
				"Deseja inserir uma seção na qual constará dicas para os clientes terem a melhor experiência possível no o seu evento?",
			importantInfoQuestion:
				"Defina abaixo informações importantes sobre o seu evento, atribuindo-lhes um título e um texto que o complemente.",
			tipTitle: "Dica {{index}} - Título",
			tipTitlePlaceholder: "Digite o título da dica {{index}}",
			tipDescription: "Dica {{index}} - Texto informativo complementar ao título",
			tipDescriptionPlaceholder: "Digite o texto complementar da dica  {{index}}",
			addMoreTip: "Adicionar mais dicas",
			deleteTip: "Apagar dica",
		},
		validations: {
			required: "Campo obrigatório",
			requiredOption: "Selecione uma opção",
			invalidSlugRules: "uma ou mais regras não foram atendidas",
			maxValuPerUserLowerThanMinValue:
				"Valor máximo por usuário deve ser maior que o valor mínimo",
			presetedValueLowerThanMinValue:
				"Valor da recarga deve ser maior que o valor mínimo",
			presetedValueHigherThanMaxValuePerUser:
				"Valor da recarga deve ser menor que o valor máximo por usuário",
			presetedValueAlreadyExists: "Valor da recarga já existe",
		},
	},
	report: {
		precharge: "Pré-carga",
		edit: "Editar site",
		showSite: "Visualizar site",
		costumersTitle: "{{value}} pessoas",
		costumersDescription: "Fizeram pré-carga",
		ticketsDescription: "Ticket médio",
		collectedDescription: "Valor arrecadado",
		redeemedDescription: "Valor resgatado",
		maleTitle: "{{value}} homens",
		femaleTitle: "{{value}} mulheres",
		otherTitle: "{{value}} outros",
		paymentMethod: "Modo de pagamento",
		value: "Valor",
		prechargePerDay: "Pré-cargas por dia",
		date: "Data",
		dateHour: "Data/Hora",
		name: "Nome",
		document: "Documento",
		email: "Email",
		bonus: "Bônus",
		cardSymmary: "Cartão utilizado",
		cardBrand: "Bandeira do cartão",
		filter: {
			name: "Nome do cliente",
			document: "Documento",
			email: "Email",
			acquirerTransactionId: "Transação adquirente",
			paymentMethod: "Método de pagamento",
			status: "Status",
		},
		creditCard: "Cartão de Crédito",
		pix: "PIX",
		billing: "Faturamento",
		status: {
			pendingReview: "Pendente",
			refunded: "Reembolsado",
			refused: "Recusado",
			success: "Aprovado",
		},
		errors: {
			hasWhitespace: "Não pode conter espaços",
			hasUpperCase: "Não pode conter letras maiúsculas",
			hasSpecialCharacter: "Não pode conter caracteres especiais",
		},
	},
};
